import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from '../../../utils/Select';
import * as Validate from '../../../utils/CommonValidate';
import DatePicker from '../../../components/DatePicker';
import ProposerItems from './ProposerItems';

const InsuredPersonsAdultChildren = ({ respData, fieldValue, setFieldValue, noOfInsured, minNoOfInsured, onChangeInputSelectInsuredFields, onChangeDateInsuredFields,calculateAge,handleCheckboxChange,isChecked, type, label }) => {
    
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(true);
    const [currentNoOfInsuredPerson, setCurrentNoOfInsuredPerson] = useState(null);
    const toggle = () => {setPanelOpen(!panelOpen)};
    useEffect(() => {
        if (respData.insuredFields.length) {
            let currentFieldValue = {...fieldValue};
    
            // set data into currentFieldValue[type] based on no of insured
            if(currentFieldValue[type].length == 0) {
                for (let i=0; i<noOfInsured; i++) {
                    let insuredPersonList = {}
                    respData.insuredFields.forEach((insuredField) => {
                        if (insuredField.key === "dob") {
                            insuredPersonList[insuredField.key] = {value: ""}
                        } else {
                            insuredPersonList[insuredField.key] = {value: "", desc: ""}
                        }
                    })
                    currentFieldValue[type].push(insuredPersonList);
                }
            }

            setFieldValue(currentFieldValue);
            setCurrentNoOfInsuredPerson(noOfInsured)
        }
    }, [fieldValue["riskItems"]["tripType"].value]);

    const loopInsuredPerson = () => {
            let fields = [];
            if ( noOfInsured === currentNoOfInsuredPerson &&
            fieldValue[type] ) { 
                for (let i = 0; i < noOfInsured; ++i) {
                    if (i === 0 && type === "adultInsuredItems" ) {
                        fields.push(
                            <Col key={i} xs="12" sm="12" md="12" className="mt-3">
                                <div className="container-flex">
                                    <Label><b><u>{t("insuredPerson") + " "+ (i+1)}<br></br><i>{t2('insuredPerson') + " "+ (i+1)}</i></u></b></Label>
                                    <Label className={"required"} style={{ marginLeft: "50px"}}>
                                        {t("checkBox")} <br /><i>{t2('checkBox')}</i>
                                    </Label>
                                    <div className="ml-4" style={{ display: "flex", alignItems: "center" }}>
                                        <Input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) =>handleCheckboxChange(e,type)}
                                            style={{ marginLeft: "15px", width: "18px", height: "18px" }}
                                        />
                                        <span style={{marginLeft: "40px"}}>Ya</span> 
                                    </div>
                                </div>
                            </Col>
                        );
                    } else {
                        fields.push(
                            <Col key={i} xs="12" sm="12" md="12" className="mt-3">
                                <Label><b><u>{t("insuredPerson") + " "+ (i+1)}<br></br><i>{t2('insuredPerson') + " "+ (i+1)}</i></u></b></Label>
                            </Col>
                        )
                    }
                    respData.insuredFields.map((insuredField) => {
                        const configuredMinNumber = i < minNoOfInsured;
                        if (insuredField.key === "name" ) {
                            fields.push(
                                <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                        <Label className={configuredMinNumber ? "required" : ""}>
                                            {t("trnx.insured.name")}
                                            <br></br>
                                            <i>{t2('trnx.insured.name')}</i>
                                        </Label>
                                    </Col>                                    
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <Input
                                            id={"insuredPerson_name_" + i}
                                            name="name"
                                            maxLength={100}
                                            value={fieldValue[type][i] ? fieldValue[type][i].name.value : ""}
                                            onChange={(e) => onChangeInputSelectInsuredFields(e, type, i, false)}
                                        />
                                        <div className="error"><span id={"errMsg_"+type+"_name_"+i}/></div>
                                    </Col>
                                </Col> 
                            )
                        }
                        if (insuredField.key === "identityNo" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label>{t("trnx.insured.identityNo")}<br></br><i>{t2('trnx.insured.identityNo')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_identityNo_"+i} name="identityNo" maxLength={50} 
                                        value={fieldValue[type][i].identityNo.value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i, false)} 
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_identityNo_"+i}/></div>
                                </Col>
                            </Col>
                            )
                        } 
                        if (insuredField.key === (type === "childrenInsuredItems" ? "childDob" : "dob")) {
                            fields.push(
                                <Col key={insuredField.key + "_" + i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                        <Label className={configuredMinNumber ? "required" : ""}>
                                            {t("trnx.insured.dob")}
                                            <br></br>
                                            <i>{t2('trnx.insured.dob')}</i>
                                        </Label>
                                    </Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <DatePicker className="form-control datepicker-icon" 
                                            placeholderText={t('placeholder.dateFormat')}
                                            selected={fieldValue[type][i][type === "childrenInsuredItems" ? "childDob" : "dob"].value} 
                                            onChange={value => onChangeDateInsuredFields(value, type, (type === "childrenInsuredItems" ? "childDob" : "dob"), i)} 
                                            id={`insuredPerson_${type === "childrenInsuredItems" ? "childDob" : "dob"}_${i}`} 
                                            name={type === "childrenInsuredItems" ? "childDob" : "dob"} 
                                        />
                                        {type === "childrenInsuredItems" ? (<div className="error"><span id={"errMsg_"+type+"_childDob_"+i}/></div>):<div className="error"><span id={"errMsg_"+type+"_dob_"+i}/></div>}                                        
                                    </Col>
                                </Col>
                            );
                        }
                        const dobField = type === "childrenInsuredItems" ? "childDob" : "dob"; 
                        const isDobFilled = !!fieldValue[type][i]?.[dobField]?.value;
                        const dobValue = fieldValue[type][i]?.[dobField]?.value;
                        const age = isDobFilled ? calculateAge(dobValue) : fieldValue[type][i]?.age?.value || "";
                        if (insuredField.key === "age") {
                            fields.push(
                                <Col key={"age_" + i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                        <Label className={configuredMinNumber ? "required" : ""}>
                                            {t("trnx.insured.age")}
                                            <br></br>
                                            <i>{t2('trnx.insured.age')}</i>
                                        </Label>
                                    </Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <Input
                                            id={"insuredPerson_age_" + i}
                                            name="age"
                                            maxLength={3}
                                            value={age}
                                            readOnly={isDobFilled}
                                            onChange={(e) => onChangeInputSelectInsuredFields(e, type, i, false)}
                                            onKeyPress={(event) => Validate.numeric(event)}
                                        />
                                        <div className="error"><span id={"errMsg_"+type+"_age_"+i} /></div>
                                    </Col>
                                </Col>
                            );
                        }
                        if (insuredField.key === "gender" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label>{t("trnx.insured.gender")}<br></br><i>{t2('trnx.insured.gender')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Select id={"insuredPerson_gender_"+i} name="gender"
                                        defaultVal={fieldValue[type]["gender"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i, true)}
                                        optionList={respData.lookupParams ? respData.lookupParams["GENDER"] : ""}
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_gender_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "email" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.email")}<br></br><i>{t2('trnx.insured.email')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_email_"+i} name="email" maxLength={100} 
                                        value={fieldValue[type]["email"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i, false)} 
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_email_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "mobileNo" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.mobileNo")}<br></br><i>{t2('trnx.insured.mobileNo')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_mobileNo_"+i} name="mobileNo" maxLength={30} 
                                        value={fieldValue[type]["mobileNo"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i, false)} 
                                        onKeyPress={(event) => Validate.number(event)}
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_mobileNo_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "fullAddress" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label>{t("trnx.insured.fullAddress")}<br></br><i>{t2('trnx.insured.fullAddress')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input 
                                        rows={3}
                                        type='textarea'
                                        id={"insuredPerson_fullAddress_"+i} name="fullAddress" maxLength={150} 
                                        value={fieldValue[type][i]["fullAddress"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i ,false)} 
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_fullAddress_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "province" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label>{t("trnx.insured.province")}<br></br><i>{t2('trnx.insured.province')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Select id={"insuredPerson_province_"+i} name="province" 
                                        defaultVal={fieldValue[type]["province"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i, true)}
                                        optionList={respData.lookupParams ? respData.lookupParams["PROVINCE"] : ""}
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_province_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "city" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label>{t("trnx.insured.city")}<br></br><i>{t2('trnx.insured.city')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Select id={"insuredPerson_city_"+i} name="city" 
                                        defaultVal={fieldValue[type]["city"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i,true)}
                                        optionList={respData.lookupParams ? respData.lookupParams["CITY"] : ""}
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_city_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "postCode" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label>{t("trnx.insured.postCode")}<br></br><i>{t2('trnx.insured.postCode')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_postCode_"+i} name="postCode" maxLength={6} 
                                        value={fieldValue[type]["postCode"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, type, i, false)} 
                                        onKeyPress={(event) => Validate.number(event)}
                                    />
                                    <div className="error"><span id={"errMsg_"+type+"_postCode_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        return true;
                    })
                }
                return fields;
            } else return fields
    };

    if(noOfInsured != 0 ) return (
        <div>
        <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
            onClick={toggle}>{t("insuredPersonalInfo")} ({t(label)})<br></br><i>{t2('insuredPersonalInfo')} ({t2(label)})</i>
        </Button>
        <Collapse isOpen={panelOpen}>
            <Card>
                <CardBody>
                    <div className="container-flex">
                        {fieldValue[type].length ? loopInsuredPerson() : ""}
                    </div>
                </CardBody>
            </Card>
        </Collapse>
        </div>
    )
    else return "";
    
};

export default InsuredPersonsAdultChildren;