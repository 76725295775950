import React, { useState, useEffect } from 'react';
import { Label, Input, Button, Container, Row, Col } from 'reactstrap';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import { Get, Post } from '../../utils/AxFetch';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from './../../components/DatePicker';
import * as Validate from './../../utils/CommonValidate';
import Select from './../../utils/Select';

const AccountDetail = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllowEdit = useSelector(state => state.sideMenuReducer.functionItems.accountDetail ? state.sideMenuReducer.functionItems.accountDetail.includes('EDIT') : '');
    const [action,setAction] = useState(props.location.state.action);
    const [selected,setSelected] = useState(props.location.state.selected);
    const [message,setMessage] = useState("");
    const [dropdownList,setDropdownList] = useState({activeCompanyList:[], roleList:[]});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isErrReturned, setErrReturned] = useState(false);

    const validateForm = () => {
        let message = '';

        if (!$('select[name="companyId"]').val()) message += t('error.message.select.blank', { 0: t('company') })+'\n'

        if (!$('input[name="loginId"]').val()) message += t('error.message.input.blank', { 0: t('accountloginid') })+'\n'

        if (!$('input[name="name"]').val()) message += t('error.message.input.blank', { 0: t('accountname') })+'\n'

        if (!($('input[name="effFrom"]').val() && $('input[name="effTo"]').val())) {
            message += t('error.message.select.blank', { 0: t('effectivedatefrom') })+'\n'
        } else if (!(Validate.validateDate($('input[name="effFrom"]').val(), $('input[name="effTo"]').val()))) {
            message += t('error.message.greaterthan.effectivedate')+'\n'
        }

        if (!$('input[name="email"]').val()) {
            message += t('error.message.input.blank', { 0: t('email') })+'\n'
        } else if (!Validate.validateEmail($('input[name="email"]').val())) {
            message += t('error.message.invalid.field', { 0: t('email') })+'\n'
        }
        
        if (!$('select[name="roleId"]').val()) message += t('error.message.select.blank', { 0: t('role') })+'\n'

        if (!$('select[name="status"]').val()) message += t('error.message.select.blank', { 0: t('status') })+'\n'

        if(!Validate.validateIdentity($('input[name="identityno"]').val())) {
            message += t('error.message.invalid.field', {0: t('identityno')})+'\n'
        }

        if (message) {
            setMessage(message);
            return false;
        }
        return true;
    };

    const populateField = (data) => {
        $('input[name="name"]').val(data.name);
        $('select[name="companyId"]').val(data.companyId); 
        $('select[name="roleId"]').val(data.roleId);
        $('input[name="loginId"]').val(data.loginId);
        $('select[name="status"]').val(data.active.toString());
        $('input[name="email"]').val(data.email);
        $('input[name="identityNo"]').val(data.identityNo);
        $('input[name="designation"]').val(data.designation);
        $('#lastUpdateOn').text(data.lastModifiedDatetime);
        $('#lastUpdateBy').text(data.lastModifiedByName);

        if (data.effectiveStartDate) {
            let dateParts = data.effectiveStartDate.split("/");
            setStartDate(new Date(dateParts[2], dateParts[1] - 1, dateParts[0]));
        }
        if (data.effectiveEndDate) {
            let dateParts = data.effectiveEndDate.split("/");
            setEndDate(new Date(dateParts[2], dateParts[1] - 1, dateParts[0]));
        }
    }

    const handleSave = async dispatch => {
        let url = '';
        let postObj = {};
        let selectId = null;

        if (validateForm()) {
            if (action === 'NEW') {
                url = urlConstant.URL_ACCOUNT_CREATE;
            } else if (action === 'EDIT') {
                url = urlConstant.URL_ACCOUNT_UPDATE;
                selectId = selected;
            }

            postObj = {
                name: $('input[name="name"]').val(), 
                companyId: $('select[name="companyId"]').val(),
                roleId: $('select[name="roleId"]').val(), 
                loginId: $('input[name="loginId"]').val(),
                active: $('select[name="status"]').val(), 
                email: $('input[name="email"]').val(), 
                effectiveStartDate: $('input[name="effFrom"]').val(),
                effectiveEndDate: $('input[name="effTo"]').val(),
                identityNo: $('input[name="identityNo"]').val(),
                designation: $('input[name="designation"]').val(),
                id: selectId
            };

            if(action) {
                let resp = await Post(urlConstant.URL_BASED + url, postObj, dispatch);

                if(!resp.isError && resp.respData.data) {
                    if (action === 'NEW') {
                        setMessage(t('message.save.success', { 0: t('account'), 1: t('created') }));
                        setSelected(resp.respData.data.id);
                        setAction('EDIT');
                    } else if (action === 'EDIT') {
                        setMessage(t('message.save.success', { 0: t('account'), 1: t('updated') }));
                    }
                    populateField(resp.respData.data);
                }
            }
        }
        window.scrollTo(0, 0);
    }

    let accountId = '';
    if (selected) accountId = selected;

    useEffect(() => {
        const dropdownLists = async dispatch => {
            let activeCompany, role = [];

            let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);
            if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
                activeCompany = activeCompanyResp.respData.data.map(company => {
                    return {shortCode: company.id, value: company.companyName}
                });
            }else{
                setErrReturned(activeCompanyResp.isError);
                return;
            }
    
            let roleResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ROLE_LIST, null, dispatch);
            if(!roleResp.isError && roleResp.respData.data) {
                role = roleResp.respData.data.map(role => {
                    return {shortCode: role.id, value: role.name}
                });
            }else{
                setErrReturned(roleResp.isError);
                return;
            }
                         
            setDropdownList({activeCompanyList: activeCompany, roleList: role});
                        
            if (action === 'EDIT') {
                dispatch(retrieveAccount);
            }
        }
    
        const retrieveAccount = async dispatch => {
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_RETRIEVE + accountId, null, dispatch);
            
            if(!resp.isError && resp.respData.data) { 
                populateField(resp.respData.data);
            }else{
                setErrReturned(resp.isError);
            }
        }

        if ((dropdownList.activeCompanyList.length === 0) || (dropdownList.roleList.length === 0)) dispatch(dropdownLists);
        window.scrollTo(0, 0);
    }, [action, accountId, dropdownList, dispatch])
    
    if (action) {
        let editDisabled = (action === 'EDIT');
        return (
            <div>
                <Container>
                    { message? <div className="error"><p>{message}</p></div>: null }
                    <Row className="mt-3"><Col><h2>{t('accountmaintenance')}</h2></Col></Row>
                    <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('accountdetail')}</h4></Col></Row>
                    <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                    
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('company')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Select name='companyId' optionList={dropdownList.activeCompanyList}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('accountloginid')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input disabled={editDisabled} type="text" name="loginId" placeholder={t('placeholder.enter', { 0: t('accountloginid') })} readOnly={!(action === "NEW")} maxLength={20} onKeyPress={(event) => Validate.alphanumericUnderscore(event)}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('accountname')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="name" placeholder={t('placeholder.enter', { 0: t('accountname') })} maxLength={50}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('effectivedatefrom')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col sm={{ size: 2 }} md="2"><DatePicker className="form-control datepicker-icon" selected={startDate} onChange={date => setStartDate(date)} name="effFrom"/></Col>
                        <Col sm={{ size: 1 }} md="1" className="text-center"><Label>{t('effectivedateto')}</Label></Col>
                        <Col sm={{ size: 2 }} md="2" ><DatePicker className="form-control datepicker-icon" selected={endDate} onChange={date => setEndDate(date)} name="effTo"/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('identityno')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="tel" name="identityNo" placeholder={t('placeholder.enter', { 0: t('identityno') })} onKeyPress={(event) => Validate.identityKeyPress(event)} maxLength={16}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('email')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="email" name="email" placeholder={t('placeholder.enter', { 0: t('email') })}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('designation')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Input type="text" name="designation" placeholder={t('placeholder.enter', { 0: t('designation') })} maxLength={30}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('role')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Select name='roleId' optionList={dropdownList.roleList}/></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">{t('status')}</Label></Col>
                        <Col xs="2" sm="1" md="1">:</Col>
                        <Col xs="12" sm="7" md="5"><Select name='status' optionList={Constant.statusList} defaultVal="true"/></Col>
                    </Row>
                    {action === 'EDIT' ? 
                        <div>
                            <Row className="mt-3">
                                <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('lastupdateon')}</Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="7" md="5"><span id='lastUpdateOn'></span></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('lastupdateby')}</Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="7" md="5"><span id='lastUpdateBy'></span></Col>
                            </Row>
                        </div>
                    : ''}
                </Container>
                <div style={{textAlign: 'center'}}>
                    {(isAllowEdit || action === 'NEW') && !isErrReturned ?<Button className="mt-3" onClick={() => dispatch(handleSave)}>{t('button.save')}</Button> : <></>}{' '}
                    <Button className="mt-3" onClick={() => dispatch(push('/account'))}>{t('button.back')}</Button>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default AccountDetail;
