import * as Constant from './../constants/Constant';
import { cryptoConfig } from "../constants/Constant";

var CryptoJS = require("crypto-js");
var crypto = require("crypto");

export const encrypt = (password) => {
    const encryptedBase64Key = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(Constant.secretPassphrase));
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    var encryptedData = null;

    encryptedData = CryptoJS.AES.encrypt(password, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encryptedData.ciphertext.toString(CryptoJS.enc.Base64);
}

export const decrypt = (encryptedData) => {
    const encryptedBase64Key = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(Constant.secretPassphrase));
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    var decryptedData = CryptoJS.AES.decrypt(encryptedData, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return decryptedData.toString(CryptoJS.enc.Utf8);
}

export const hmacFileGateway = (httpMethod, url, secretKey, transRefNo, timestamp) => {
    var trimmedMessage = transRefNo.replaceAll("\\n|\\r|\\s", "");
    var base64Message = CryptoJS.SHA256(trimmedMessage).toString(CryptoJS.enc.Base64);
    var hashMac = httpMethod + ":" + url + ":" + timestamp + ":" +base64Message;
    var hashedData = CryptoJS.HmacSHA256(hashMac, secretKey);
    return CryptoJS.enc.Base64.stringify(hashedData);
}

export const encryptNow = (password) => {
    //console.log('TEST encryptNow');
	try{
        // random initialization vector
        var iv = crypto.randomBytes(cryptoConfig.ivLength);

        // random salt
        var salt = crypto.randomBytes(64);

        // derive key: 32 byte key length - in assumption the masterkey is a cryptographic and NOT a password there is no need for
        // a large number of iterations. It may can replaced by HKDF
        var key = crypto.pbkdf2Sync(cryptoConfig.masterKey, salt, 2145, 32, cryptoConfig.digest);

        // AES 256 GCM Mode
        var cipher = crypto.createCipheriv(cryptoConfig.cipherAlgorithm, key, iv);

        // encrypt the given text
        var encrypted = Buffer.concat([cipher.update(password, 'utf8'), cipher.final()]);

        // extract the auth tag
        var tag = cipher.getAuthTag();

        // generate output
        var encryptedData = Buffer.concat([salt, iv, encrypted, tag]).toString('base64');
        //console.log("encryptedData = " + encryptedData);
        
        return encryptedData;

    }catch(e){
        console.log(e);
    }

    // error
    return null;
}

export const decryptNow = (encryptedData) => {
	//console.log('TEST decryptNow');
	try {
        // base64 decoding
        var bData = Buffer(encryptedData, 'base64');
        var salt = bData.slice(0, 64);
        var iv = bData.slice(64, 76);
        var text = bData.slice(76, bData.length - 16);
        var tag = bData.slice(bData.length - 16);

        // derive key using; 32 byte key length
        var key = crypto.pbkdf2Sync(cryptoConfig.masterKey, salt , 2145, 32, cryptoConfig.digest);
        // AES 256 GCM Mode
        var decipher = crypto.createDecipheriv(cryptoConfig.cipherAlgorithm, key, iv);
        decipher.setAuthTag(tag);

        // decrypt the given text
        var decrypted = decipher.update(text, 'binary', 'utf8') + decipher.final('utf8');
        //console.log("decrypted = " + decrypted)

        return decrypted;

    }catch(e){
        console.log(e)
    }

    // error
    return null;
}


// Assign the object to a variable before exporting it as default
const exportedFunctions = {
    encrypt,
    decrypt,
    hmacFileGateway,
	encryptNow,
    decryptNow
};

export default exportedFunctions;
