import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from '../../../utils/Select';
import * as Validate from './../../../utils/CommonValidate';
import DatePicker from './../../../components/DatePicker';


const InsuredPersons = ({ respData, fieldValue, setFieldValue, noOfInsured, onChangeInputSelectInsuredFields, onChangeDateInsuredFields ,calculateAge,handleCheckboxChange,isChecked, handleUnCheckCheckboxPerson}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(true);
    const [currentNoOfInsuredPerson, setCurrentNoOfInsuredPerson] = useState(fieldValue["riskItems"]["noOfInsured"] ? fieldValue["riskItems"]["noOfInsured"].value : null);

    const toggle = () => {setPanelOpen(!panelOpen)};

    const riskItemExist = (key) => {
        if (respData.riskFields) {
            return respData.riskFields.some(function(riskItem) {
                if (riskItem.key === key) {
                    return true;
                } else return false;
            }); 
        } else return false;
    }

    useEffect(() => {
        const initialInsuredValue = () => {
            if (respData.insuredFields.length) {
                let currentFieldValue = {...fieldValue};
                let latestInsuredPerson = fieldValue["riskItems"]["noOfInsured"].value;
                let size = latestInsuredPerson - currentNoOfInsuredPerson;

                if(latestInsuredPerson === ""){
                    handleUnCheckCheckboxPerson();
                }
                if (size < 0) {
                    fieldValue["insuredItems"].splice(latestInsuredPerson, (-1 * size));
                }
                else {
                    for (let i=0; i<size; i++) {
                        let insuredPersonList = {}
                        respData.insuredFields.forEach((insuredField) => {
                            if (insuredField.key === "dob") {
                                insuredPersonList[insuredField.key] = {value: ""}
                            } else {
                                insuredPersonList[insuredField.key] = {value: "", desc: ""}
                            }
                        })
                        currentFieldValue["insuredItems"].push(insuredPersonList);
                    }
                }
                
                setFieldValue(currentFieldValue);
                setCurrentNoOfInsuredPerson(noOfInsured);
            }
        }
        if (noOfInsured !== currentNoOfInsuredPerson) initialInsuredValue();
    }, [respData, fieldValue, noOfInsured, currentNoOfInsuredPerson, setFieldValue]);

    const loopInsuredPerson = () => {
            let fields = [];
            if ((currentNoOfInsuredPerson === fieldValue["riskItems"]["noOfInsured"].value )
                && fieldValue["insuredItems"] ) { 
                for (let i = 0; i < fieldValue["riskItems"]["noOfInsured"].value; ++i) {
                    if (i === 0) {
                        fields.push(
                            <Col key={i} xs="12" sm="12" md="12" className="mt-3">
                                <div className="container-flex">
                                    <Label><b><u>{t("insuredPerson") + " "+ (i+1)}<br></br><i>{t2('insuredPerson') + " "+ (i+1)}</i></u></b></Label>
                                    <Label className={"required"} style={{ marginLeft: "50px"}}>
                                        {t("checkBox")} <br /><i>{t2('checkBox')}</i>
                                    </Label>
                                    <div className="ml-4" style={{ display: "flex", alignItems: "center" }}>
                                        <Input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) =>handleCheckboxChange(e,"insuredItems")}
                                            style={{ marginLeft: "15px", width: "18px", height: "18px" }}
                                        />
                                        <span style={{marginLeft: "40px"}}>Ya</span> 
                                    </div>
                                </div>
                            </Col>
                        );
                    } else {
                        fields.push(
                            <Col key={i} xs="12" sm="12" md="12" className="mt-3">
                                <Label><b><u>{t("insuredPerson") + " "+ (i+1)}<br></br><i>{t2('insuredPerson') + " "+ (i+1)}</i></u></b></Label>
                            </Col>
                        )
                    }
                    respData.insuredFields.map((insuredField) => {
                        if (insuredField.key === "name" ) {
                            fields.push(
                                <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.name")}<br></br><i>{t2('trnx.insured.name')}</i></Label></Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <Input id={"insuredPerson_name_"+i} name="name" maxLength={100} 
                                            value={fieldValue["insuredItems"][i] ? fieldValue["insuredItems"][i].name.value : ""} 
                                            onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, false)} 
                                        />
                                        <div className="error"><span id={"errMsg_insuredPerson_name_"+i}/></div>
                                    </Col>
                                </Col> 
                            )
                        }
                        if (insuredField.key === "identityNo" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.identityNo")}<br></br><i>{t2('trnx.insured.identityNo')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_identityNo_"+i} name="identityNo" maxLength={50} 
                                        value={fieldValue["insuredItems"][i].identityNo.value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, false)} 
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_identityNo_"+i}/></div>
                                </Col>
                            </Col>
                            )
                        }
                        if (insuredField.key === "dob" ) {
                            fields.push(
                                <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.dob")}<br></br><i>{t2('trnx.insured.dob')}</i></Label></Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <DatePicker className="form-control datepicker-icon" 
                                            placeholderText={t('placeholder.dateFormat')}
                                            selected={fieldValue["insuredItems"][i].dob.value} 
                                            onChange={value => onChangeDateInsuredFields(value, "insuredItems", "dob", i)} 
                                            id={"insuredPerson_dob_"+i} 
                                            name="dob" 
                                        />
                                        <div className="error"><span id={"errMsg_insuredPerson_dob_"+i}/></div>
                                    </Col>
                                </Col>
                            )
                        }
                        const isDobFilled = !!fieldValue["insuredItems"][i]?.dob?.value;
                        const dobValue = fieldValue["insuredItems"][i]?.dob?.value;
                        const age = isDobFilled ? calculateAge(dobValue) : fieldValue["insuredItems"][i]?.age?.value || "";
                        if (insuredField.key === "age") {
                            fields.push(
                                <Col key={"age_" + i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                    <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                        <Label className="required">{t("trnx.insured.age")}<br /><i>{t2('trnx.insured.age')}</i></Label>
                                    </Col>
                                    <Col xs="2" sm="1" md="1">:</Col>
                                    <Col xs="12" sm="6" md="6">
                                        <Input
                                            id={"insuredPerson_age_" + i}
                                            name="age"
                                            maxLength={3}
                                            value={age}
                                            readOnly={isDobFilled}
                                            onChange={(e) => onChangeInputSelectInsuredFields(e, "insuredItems", i, false)} 
                                            onKeyPress={(event) => Validate.numeric(event)}
                                        />
                                        <div className="error"><span id={"errMsg_insuredPerson_age_" + i} /></div>
                                    </Col>
                                </Col>
                            );
                        }
                        if (insuredField.key === "gender" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.gender")}<br></br><i>{t2('trnx.insured.gender')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Select id={"insuredPerson_gender_"+i} name="gender"
                                        defaultVal={fieldValue["insuredItems"]["gender"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, true)}
                                        optionList={respData.lookupParams ? respData.lookupParams["GENDER"] : ""}
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_gender_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "email" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.email")}<br></br><i>{t2('trnx.insured.email')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_email_"+i} name="email" maxLength={100} 
                                        value={fieldValue["insuredItems"]["email"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, false)} 
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_email_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "mobileNo" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.mobileNo")}<br></br><i>{t2('trnx.insured.mobileNo')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_mobileNo_"+i} name="mobileNo" maxLength={12} 
                                        value={fieldValue["insuredItems"]["mobileNo"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, false)} 
                                        onKeyPress={(event) => Validate.number(event)}
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_mobileNo_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "fullAddress" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.fullAddress")}<br></br><i>{t2('trnx.insured.fullAddress')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input 
                                        rows={3}
                                        type='textarea'
                                        id={"insuredPerson_fullAddress_"+i} name="fullAddress" maxLength={150} 
                                        value={fieldValue["insuredItems"][i]["fullAddress"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i ,false)} 
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_fullAddress_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "province" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.province")}<br></br><i>{t2('trnx.insured.province')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Select id={"insuredPerson_province_"+i} name="province" 
                                        defaultVal={fieldValue["insuredItems"]["province"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, true)}
                                        optionList={respData.lookupParams ? respData.lookupParams["PROVINCE"] : ""}
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_province_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "city" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.city")}<br></br><i>{t2('trnx.insured.city')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Select id={"insuredPerson_city_"+i} name="city" 
                                        defaultVal={fieldValue["insuredItems"]["city"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i,true)}
                                        optionList={respData.lookupParams ? respData.lookupParams["CITY"] : ""}
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_city_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        if (insuredField.key === "postCode" ) {
                            fields.push(
                            <Col key={insuredField.key+"_"+i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                <Col xs="10" sm="5" md="5" className="paddingRightZero"><Label className="required">{t("trnx.insured.postCode")}<br></br><i>{t2('trnx.insured.postCode')}</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="6" md="6">
                                    <Input id={"insuredPerson_postCode_"+i} name="postCode" maxLength={6} 
                                        value={fieldValue["insuredItems"]["postCode"].value} 
                                        onChange={e => onChangeInputSelectInsuredFields(e, "insuredItems", i, false)} 
                                        onKeyPress={(event) => Validate.number(event)}
                                    />
                                    <div className="error"><span id={"errMsg_insuredPerson_postCode_"+i}/></div>
                                </Col>
                            </Col> 
                            )
                        }
                        return true;
                    })
                }
                return fields;
            } else return fields
    };

    if (respData.insuredFields.length && riskItemExist("noOfInsured") && currentNoOfInsuredPerson) return (
        <div>
        <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
            onClick={toggle}>{t("insuredPersonalInfo")}<br></br><i>{t2('insuredPersonalInfo')}</i>
        </Button>
        <Collapse isOpen={panelOpen}>
            <Card>
                <CardBody>
                    <div className="container-flex">
                        {fieldValue["insuredItems"].length ? loopInsuredPerson() : ""}
                    </div>
                </CardBody>
            </Card>
        </Collapse>
        </div>
    )
    else return "";
};

export default InsuredPersons;