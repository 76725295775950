import React, {useState} from 'react';
import { Input } from 'reactstrap';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import * as urlConstant from '../constants/urlConstant';
import { Get } from '../utils/AxFetch';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const AutoComplete = (props) => {
    const { t } = useTranslation();
    const [fieldValue] = useState(props.fieldValue?props.fieldValue:"" );
    const [item] = useState(props.item?props.item:"" );
    const [options, setOptions] = useState(props.optionList?props.optionList:[]);
    const dispatch = useDispatch();
    let attributes = {...props};

    delete attributes.optionList;
    delete attributes.defaultVal;
    delete attributes.fieldValue;
    delete attributes.setFieldValue;
    delete attributes.item;
    delete attributes.respData;
    delete attributes.setRespData;

    if(props.defaultVal) {
        attributes.defaultValue = props.defaultVal;
    }

    const onClick = (e) => {
        if (e.target) {
            let currentFieldValue = {...fieldValue};
            let id = $("#options_"+attributes.name+ " option[value='" + e.target.value + "']").attr('data-id');
            for (var key in currentFieldValue) {
                if (key === item) {
                    if (id !== undefined) {
                        currentFieldValue[item][e.target.name].desc = e.target.value
                        currentFieldValue[item][e.target.name].value = id
                        props.setFieldValue(currentFieldValue);
                    } else if (e.target.value === '') {
                        currentFieldValue[item][e.target.name].desc = ''
                        currentFieldValue[item][e.target.name].value = ''
                        props.setFieldValue(currentFieldValue);
                    }

                    if(e.target.name === "travelDestCategory") {
                        props.destCategoryOnChange();
                    }
                    if(e.target.name === "travelDestCountry") {
                        props.destCountryOnChange();
                    }
                    if(e.target.name === "tripType") {
                        props.tripTypeOnChange();
                    }
                    if(e.target.name === "durationType") {
                        props.durationTypeOnchange(e);
                    }
                    if(e.target.name === "noOfInsured") {
                        props.noOfInsuredOnChange(e);
                    }
                    if(e.target.name === "riskProvince") {
                        currentFieldValue[item]["riskCity"].desc = ''
                        currentFieldValue[item]["riskCity"].value = ''
                        props.setFieldValue(currentFieldValue);
                    }
                    if(e.target.name === "province") {
                        currentFieldValue[item]["city"].desc = ''
                        currentFieldValue[item]["city"].value = ''
                        props.setFieldValue(currentFieldValue);
                    }
                }
            }
        }
    }

    useEffect(() => {
        setOptions(props.optionList);
    }, [props.optionList])

    const getCities = (e, id) => {
        if(e.target.name === "province" || e.target.name === "riskProvince") {
            let provinceShortCode = id || "ALL";
            Get(
                urlConstant.URL_BASED+urlConstant.URL_TRNX_PROVINCE_CITY+`${provinceShortCode}/city`,
                {},
                dispatch
            ).then(res => {
                if(!res.isError) {
                    if(e.target.name === "province") {
                        const data = res.respData.data.commonLookupList;
                        let newRespData = structuredClone(props.respData);
                        newRespData.lookupParams["CITY"] = data;
                        props.setRespData(newRespData);
                    } else {
                        const cities = res.respData.data.commonLookupList;
                        let newRespData = structuredClone(props.respData);
                        newRespData.riskFields.map(fields => {
                            if("riskCity" === fields.key)  {
                                fields.options = cities;
                            }
                        })
                        props.setRespData(newRespData);
                    }
                }
            });
        }
    }

    const onBlur = (e) => {
        let id = $("#options_"+attributes.name+ " option[value='" + e.target.value + "']").attr('data-id');
        if (e.target.value !== '' && (e.target.defaultValue !== undefined || e.target.defaultValue !== '')) {
            $("#input_"+attributes.name).val(e.target.defaultValue);
            e.persist();
            getCities(e, id);
        } else {
            $("#input_"+attributes.name).val('');
            e.persist();
            getCities(e, id);
        }
        if(e.target.name === "riskProvince") {
            $("#input_riskCity").val("");
        }
        if(e.target.name === "province") {
            $("#input_city").val("");
        }
    }

    if (props.fieldValue) {
        return(
            <div>
            <Input
                type="text"
                id={"input_"+attributes.name} 
                {...attributes}
                list={"options_"+attributes.name}
                placeholder={t("placeholder.selectsearch")}
                onChange={(e)=>onClick(e)}
                onBlur={(e)=>onBlur(e)}
            />
                <datalist id={"options_"+attributes.name}>
                    {options.map(item => {
                                return(<option key={item.shortCode} value={item.value} data-id={item.shortCode}></option>)
                            }
                        )
                    }
                </datalist>
            </div>
        );
    } else return null;
}

export default AutoComplete;