export const FieldLabels = [
    {
        title: "PRODUCT_INFO"
    },
    {
        title: "POS",
        field : [
            {key: "name"},
            {key: "identityNo"},
            {key: "dob"},
            {key: "gender"},
            {key: "email"},
            {key: "mobileNo"},
            {key: "address1"},
            {key: "address2"},
            {key: "address3"},
            {key: "province"},
            {key: "city"},
            {key: "postCode"}
        ]
    },
    {
        title: "PLAN"
    },
    {
        title: "CONFIRMATION",
    },
    {
        title: "PAYMENTSELECT"
    }
];

export const FieldValue = {
    proposerItems : {
        name : {value: ""},
        identityNo : {value: ""},
        // identityTyp : "",
        dob: {value: ""},
        gender : {value: "", desc: ""},
        email : {value: ""},
        mobileNo : {value: ""},
        fullAddress: {value: ""},
        address1 : {value: ""},
        address2 : {value: ""},
        address3 : {value: ""},
        province : {value: "", desc: ""},
        city : {value: "", desc: ""},
        postCode : {value: ""},
    },
    insuredItems : [],
    adultInsuredItems: [],
    childrenInsuredItems: [],
    infoItems : {},
    riskItems : {},
    dynamicProposerFields: {},
    questionItems : [],
}

export const PremiumResp = {
    basicBenefits: [],
    additionalBenefits: [],
    plans: []
}

export const PremiumValue = {
    getPremiumParams: {},
    additionalBenefitsChecked: [],
    planSelected: {
        code: "",
        name: "",
        coverage: {
            value: "",
            desc: ""
        },
        premium: {
            basicPremium: "",
            additionalBenefitPremium: "",
            duration: "",
            startDate: "",
            endDate: "",
            text: "",
            grossPremium: "",
            adminFee: "",
            stampDuty: "",
            discountPercentage: "",
            discountAmount: "",
            grandTotal: "",
            promoCode: "",
            promoDiscountAmount: "",
        }
    }
}