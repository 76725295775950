import * as actions from '../../actions/loginAction';
import { purgeStoredState } from 'redux-persist';
import persistConfig from './../persistConfig';

const initState = {
    token: '',
    loginstatus: false,
    username: '',
    isStaff: false,
    companyId:'',
    companyName:'',
    productVersion:"",
    companylogoImgBase64: "",
    companylogoImgStatus: "",
    lastLoginDate: "",
    isOtp: false
}

const loginReducer = (state = initState, action) => {
    switch(action.type) {
        case actions.LOGIN_SUCCESS: {
            return {
                ...state,
                loginstatus: true,
                token: action.loginRespData.token,
                id: action.loginRespData.id,
                username: action.loginRespData.name,
                isStaff: action.loginRespData.isStaff,
                companyId: action.loginRespData.companyId,
                companyName: action.loginRespData.companyName,
                lastLoginDate: action.loginRespData.lastLoginDateTime,
                isOtp: action.loginRespData.isOtp
            };
        }
        case actions.UPDATE_TOKEN: {
            return {
                ...state,
                token: action.token
            };
        }
        case actions.LOGOUT: {
            purgeStoredState(persistConfig);
            return initState;
        }
        case actions.UPDATE_PRODUCT_VERSION: {
            return {
                ...state,
                productVersion: action.productVersion
            };
        }
        case actions.DISPLAY_LOGO: {
            return {
                ...state,
                companylogoImgBase64: action.companylogoImgBase64,
                companylogoImgStatus: action.companylogoImgStatus
            }
        }
        case actions.LOGIN_PROCESS: {
            return {
                ...state,
                token: action.loginRespData.token,
                id: action.loginRespData.id,
                username: action.loginRespData.name,
                isStaff: action.loginRespData.isStaff,
                companyId: action.loginRespData.companyId,
                companyName: action.loginRespData.companyName,
                lastLoginDate: action.loginRespData.lastLoginDateTime,
                isOtp: action.loginRespData.isOtp
            }
        }
        default: {
            return state;
        }
    }
}

export default loginReducer;