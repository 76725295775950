import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Post, Get, Download } from '../../../utils/AxFetch';
import { format } from 'date-fns';
import * as urlConstant from '../../../constants/urlConstant';
import * as Constant from '../../../constants/Constant';
import ConfirmationPlan from './ConfirmationPlan';
import ConfirmationProduct from './ConfirmationProduct'
import ConfirmationProposer from './ConfirmationProposer/ConfirmationProposer';
import ConfirmationPremiumPlan from './ConfirmationPremiumPlan';
import ConfirmationDisclaimer from './ConfirmationDisclaimer';
import ConfirmationQuestion from './ConfirmationQuestion';
import { Tooltip } from 'reactstrap';

const Confirmation = ({ channel, respData, premiumResp, premiumValue, fields, fieldValue, nextSlideTransitions, adultInsuredItems, childrenInsuredItems}) => {
    const { t } = useTranslation();
    const t2 = (label) => { return t('en_translations:' + label) };
    const t3 = (label) => { return t('en_riskitems:'+label) }
    const dispatch = useDispatch();
    const [trnxCreated, setTrnxCreated] = useState()
    const [eMsg, setEMsg] = useState("");
    const [disclaimerFields, setDisclaimerFields] = useState(respData.disclaimerFields);
    const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);
    const [istooltipOpen, setIstooltipOpen] = useState(false);
    const planSelected = premiumValue.planSelected;

    const updateQuestion = () => {
        let questionList = [];
        respData.questionFields.map(questionField => {
            questionList.push({
                ax_label_id :questionField.axLabel.axLabelId,
                ax_label_en :questionField.axLabel.axLabelEn,
                value : fieldValue['questionItems'][questionField.key] ? 
                    fieldValue['questionItems'][questionField.key].value=="YES" ? 
                        "Ya" : 
                        "Tidak" 
                : "-"
            })
        })
        return questionList;
    }

    const updateProposal = () => {
        let dynamicProposerList = [];
            respData.proposerFields.map(proposalField => {
                    dynamicProposerList.push({
                        ax_label_id: proposalField.axLabel.axLabelId,
                        ax_label_en :proposalField.axLabel.axLabelEn,
                        value:fieldValue['dynamicProposerFields'][proposalField.key] ? fieldValue['dynamicProposerFields'][proposalField.key].value : "-"
                    });           
            });
        return dynamicProposerList;
    };
    
    const updateRisk = () => {
        let riskList = [];
        respData.riskFields.map(riskField => {
            riskList.push({
                ax_label_id :  riskField.axLabel && riskField.axLabel.axLabelId ? riskField.axLabel.axLabelId : t2(riskField.key) ? t2(riskField.key) : riskField.key,
                ax_label_en : riskField.axLabel && riskField.axLabel.axLabelEn ? riskField.axLabel.axLabelEn : t3(riskField.key) ? t3(riskField.key) : riskField.key,
                value: fieldValue['riskItems'][riskField.key].value instanceof Date?
                    format(fieldValue['riskItems'][riskField.key].value, Constant.dateFormat_B) :
                riskField.key.includes("sumInsured") || riskField.key.includes("SumInsured") ?
                    convertToReadable(fieldValue['riskItems'][riskField.key].value) :
                fieldValue['riskItems'][riskField.key].desc ? 
                    fieldValue['riskItems'][riskField.key].desc :
                fieldValue['riskItems'][riskField.key] ?
                    fieldValue['riskItems'][riskField.key].value:"-",
                code: riskField.key
            })
        })
        return riskList;
    }

    const updateInfo = () => {
        let infoList = [];
        respData.infoFields.map(infofield => {
            infoList.push({
                ax_label_id : infofield.axLabel.axLabelId,
                ax_label_en : infofield.axLabel.axLabelEn,
                valueList: fieldValue["infoItems"][infofield.key] ? 
                fieldValue["infoItems"][infofield.key].value.map((value, inex) => {
                return {value: value, index: inex + 1};
            }) : [],
            })
        })
        return infoList;
    }

    const updateDisclamer = () => {
        let disclaimerList = [];
        respData.disclaimerFields.map(disclaimer => {
            disclaimerList.push({
                ax_label_id : disclaimer.axLabel.axLabelId,
                ax_label_en : disclaimer.axLabel.axLabelEn,
                checked: disclaimer.checked,
                mandatory: disclaimer.isRequired,
                key: disclaimer.key
            })
        })
        return disclaimerList;
    }

    const updateAdditionalBenefit = () => {
        let additionalBenefitList = [];
        if(!premiumResp.additionalBenefits) return additionalBenefitList;
        premiumResp.additionalBenefits.map(additionalBenefit => {
            additionalBenefitList.push(additionalBenefit)
        })
        return additionalBenefitList;
    }

    const convertToReadable = (number) => {
        if(number==0) return number;
        return Constant.defaultCurrency+" "+parseInt(number).toLocaleString(Constant.defaultLocale);
    }

    const handleDownload = () => {
        const data = {
            "planName": planSelected.name,
            "coveragePeriod": planSelected.premium.duration,
            "totalPremium": convertToReadable(planSelected.premium.grandTotal),
            "basicPremium": convertToReadable(planSelected.premium.basicPremium),
            "additionalBenefit": convertToReadable(planSelected.premium.additionalBenefitPremium),
            "basicBenefitList": premiumResp.basicBenefits,
            "additionalBenefitList": updateAdditionalBenefit(),
            "adminFee": convertToReadable(planSelected.premium.adminFee),
            "stampDuty": convertToReadable(planSelected.premium.stampDuty),
            "discountAmount": convertToReadable(planSelected.premium.discountAmount),
            "productCode": respData.code,
            "productDesc": respData.desc,
            "insuredName": fieldValue.proposerItems.name && fieldValue.proposerItems.name.value ? fieldValue.proposerItems.name.value : "-",
            "ktp": fieldValue.proposerItems.identityNo && fieldValue.proposerItems.identityNo.value ? fieldValue.proposerItems.identityNo.value : "-",
            "dob": fieldValue.proposerItems.dob && fieldValue.proposerItems.dob.value ? format(fieldValue.proposerItems.dob.value, Constant.dateFormat_B) : "-",
            "gender": fieldValue.proposerItems.gender && fieldValue.proposerItems.gender.desc ? fieldValue.proposerItems.gender.desc : "-",
            "email": fieldValue.proposerItems.email && fieldValue.proposerItems.email.value ? fieldValue.proposerItems.email.value : "-",
            "mobileNo": fieldValue.proposerItems.mobileNo && fieldValue.proposerItems.mobileNo.value ? fieldValue.proposerItems.mobileNo.value : "-",
            "address": fieldValue.proposerItems.fullAddress && fieldValue.proposerItems.fullAddress.value ? fieldValue.proposerItems.fullAddress.value : "-",
            "province": fieldValue.proposerItems.province && fieldValue.proposerItems.province.desc ? fieldValue.proposerItems.province.desc : "-",
            "city": fieldValue.proposerItems.city && fieldValue.proposerItems.city.desc ? fieldValue.proposerItems.city.desc : "-",
            "postcode": fieldValue.proposerItems.postCode && fieldValue.proposerItems.postCode.value ? fieldValue.proposerItems.postCode.value : "-",
            "dynamicProposer": updateProposal(),
            "riskFields": updateRisk(),
            "infoFields": updateInfo(),
            "insuredPerson": premiumValue.getPremiumParams.insuredPersons,
            "insuredAdultPerson": premiumValue.getPremiumParams.adultInsuredPersons,
            "insuredChildPerson": premiumValue.getPremiumParams.childrenInsuredPersons,
            "questions": updateQuestion(),
            "disclaimer": updateDisclamer(),
            "durationMeasurement": respData.durationMeasurement,
            "promoCode":planSelected.premium.promoCode,
            "promoDiscountAmount": convertToReadable(planSelected.premium.promoDiscountAmount),
        }

        Download(urlConstant.URL_BASED + (channel === Constant.LOGIN_CHANNEL.CONSUMER ? urlConstant.URL_B2C_TRNX_DOWNLOAD_PDF : urlConstant.URL_TRNX_DOWNLOAD_PDF),
            Constant.downloadType.POST, data, Constant.pdfdownloadfile, dispatch)
            .then((resp) => {
                if (resp.isError === true) {
                    setIsPdfDownloaded(false);
                } else {
                    setIsPdfDownloaded(true);
                }
            })
    }

    const validationCreate = async () => {
        let error = false;

        let proposer, riskItems, infoItems, plan, proposerFields = {}
        let questionFields = {};
        let insuredItems = [];
        let adultInsuredItems = [];
        let childrenInsuredItems = [];

        let infoProposer = {};
        Object.entries(fieldValue["proposerItems"]).forEach(([key, value]) => {
            if (value.value instanceof Date) {
                let date = format(value.value, Constant.dateFormat_B);
                infoProposer = Object.assign({ [key]: date }, infoProposer);
            } else infoProposer = Object.assign({ [key]: value.value }, infoProposer);
            infoProposer = Object.assign({ identityTyp: "KTP" }, infoProposer)
        });
        proposer = infoProposer;

        if (respData.infoFields.length) {
            let info = {};
            respData.infoFields.forEach(infoField => {
                if (fieldValue["infoItems"][infoField.key]) {
                    info = Object.assign({ [infoField.key]: fieldValue["infoItems"][infoField.key].value }, info);
                }
            });
            infoItems = info;
        }
        if (respData.riskFields.length) {
            let risk = {}
            respData.riskFields.map((riskField) => {
                if (fieldValue["riskItems"][riskField.key]) {
                    if (fieldValue["riskItems"][riskField.key].value instanceof Date) {
                        let date = format(fieldValue["riskItems"][riskField.key].value, Constant.dateFormat_B);
                        return risk = Object.assign( { [riskField.key]: date }, risk );
                    } else return risk = Object.assign( { [riskField.key]: fieldValue["riskItems"][riskField.key].value }, risk );
                } else return true;
            })
            riskItems = risk;
        }

        if (respData.proposerFields.length) {
            let proposer = {}
            respData.proposerFields.map((proposerField) => {
                if (fieldValue["dynamicProposerFields"][proposerField.key]) {
                    return proposer = Object.assign( { [proposerField.key]: fieldValue["dynamicProposerFields"][proposerField.key].value }, proposer );
                } else return true;
            })
            proposerFields = proposer;
        }

        if (respData.insuredFields.length) {
            let insuredArray =[];
            fieldValue["insuredItems"].forEach((insuredItem) => {
                let insured ={};
                for (let key in insuredItem) {
                    if (insuredItem[key].value instanceof Date) {
                        let date = format(insuredItem[key].value, Constant.dateFormat_B);
                        insured = Object.assign( { [key]: date }, insured );
                    } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                }
                insuredArray.push(insured);
            });
            insuredItems = insuredArray
        }

        if (respData.insuredFields.length) {
            let insuredArray =[];
            fieldValue["adultInsuredItems"].forEach((insuredItem) => {
                let insured ={};
                let allFieldsEmpty = true

                // Do check if all the input is empty, do not push into array
                for (let key in insuredItem) {
                    if(insuredItem[key].value) allFieldsEmpty = false
                }
                if(!allFieldsEmpty) {
                    for (let key in insuredItem) {
                        if (insuredItem[key].value instanceof Date) {
                            let date = format(insuredItem[key].value, Constant.dateFormat_B);
                            insured = Object.assign( { [key]: date }, insured );
                        } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                    }
                    insuredArray.push(insured);
                }
            });
            adultInsuredItems = insuredArray
        }

        if (respData.insuredFields.length) {
            let insuredArray =[];
            fieldValue["childrenInsuredItems"].forEach((insuredItem) => {
                let insured ={};
                let allFieldsEmpty = true

                // Do check if all the input is empty, do not push into array
                for (let key in insuredItem) {
                    if(insuredItem[key].value) allFieldsEmpty = false
                }
                if(!allFieldsEmpty) {
                    for (let key in insuredItem) {
                        if (insuredItem[key].value instanceof Date) {
                            let date = format(insuredItem[key].value, Constant.dateFormat_B);
                            insured = Object.assign( { [key]: date }, insured );
                        } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                    }
                    insuredArray.push(insured);
                }
            });
            childrenInsuredItems = insuredArray
        }

        if(respData.questionFields.length) {
            let question = {}
            respData.questionFields.map(questionField => {
                if(fieldValue["questionItems"][questionField.key]) {
                    return question = Object.assign({[questionField.key]: fieldValue["questionItems"][questionField.key].value}, question);
                } else return true;
            })
            questionFields = question;
        }
        
        let planAdditionalBenefit = [];
        
        if (premiumValue.additionalBenefitsChecked.length !==0){
            premiumValue.additionalBenefitsChecked.map(additionalBenefit =>{
                if (additionalBenefit.checked === true) 
                    planAdditionalBenefit.push({ code: additionalBenefit.code })
                return true
            })
        }
        plan = {planCode: premiumValue.planSelected.code, additionalBenefits: planAdditionalBenefit}

        if(premiumValue.planSelected.premium.duration){
            plan = {...plan, duration: premiumValue.planSelected.premium.duration}
        }

        if(disclaimerFields.length !== 0) {
            error = validateDisclaimer(error);
        }

        if (error) {
            window.scrollTo(0, 0);
            return false;
        }

        respData.disclaimerFields = disclaimerFields;

        if(channel === Constant.LOGIN_CHANNEL.CONSUMER) {
            nextSlideTransitions(true);
            window.scrollTo(0, 0);
        } else if (channel === Constant.LOGIN_CHANNEL.PARTNER) {
            let url = urlConstant.URL_B2B_TRNX_CREATE;
            if(!trnxCreated){
                let resp = await Post(urlConstant.URL_BASED + url,
                {
                    productCode : respData.code,
                    proposer: proposer,
                    riskItems : riskItems,
                    infoItems : infoItems,
                    insuredPersons : insuredItems,
                    plan: plan,
                    proposerFields : proposerFields,
                    questionFields: questionFields,
                    disclaimerFields : disclaimerFields,
                    adultInsuredPersons : adultInsuredItems,
                    childrenInsuredPersons : childrenInsuredItems
                }, dispatch);
    
                if(!resp.isError && resp.respData.data){
                    setTrnxCreated(resp.respData.data);    
                    createPayment(resp.respData.data);
                }
            }else{
                createPayment(trnxCreated)
            }
        }
    }

    const createPayment = async(trans) =>{
        if(channel === Constant.LOGIN_CHANNEL.PARTNER)
            dispatch(push({pathname: Constant.PATH_ACKNOWLEDGEMENT, state: trans}));
            
        if(channel === Constant.LOGIN_CHANNEL.CONSUMER){ 
            let createPaymentResp = await Get(urlConstant.URL_BASED + urlConstant.URL_CREATE_PAYMENT_INT + trans.id+"/"+trans.paymentType, null, dispatch);                
            if(!createPaymentResp.isError && createPaymentResp.respData.data){
                dispatch(push({pathname: Constant.PATH_PAYMENT_SUBMISSION, state: {paymentResp: createPaymentResp.respData.data}}));
            }
        }
    }

    const validateDisclaimer = (error) => {
        let message = "";
        disclaimerFields.forEach(disclaimer => {
            if (disclaimer.isRequired) {
                if (!disclaimer.checked) {
                    error = true;
                    message += t('requiredCheck') + disclaimer.key;
                }
            }
        })
        if (error) setEMsg(message);
        return error;
    }

    if (fields) return (
        <div>
            <Row className="mt-3"><Col className="titleH4"><h4>{t('confirmation')}</h4></Col></Row>
            <Row><Col className="titleH4"><h6><i>{t2('confirmation')}</i></h6></Col></Row>
            <Row><Col className="borderBottom"></Col></Row>

            <ConfirmationPlan premiumValue={premiumValue} respData={respData} />
            <ConfirmationProduct respData={respData} />

            {fields.map((field, key) =>
                field.title === "POS" ?
                    <ConfirmationProposer key={key} respData={respData} field={field} fieldValue={fieldValue} adultInsuredItems={adultInsuredItems} childrenInsuredItems={childrenInsuredItems}/> :
                    '')
            }
            <ConfirmationPremiumPlan premiumValue={premiumValue} premiumResp={premiumResp} />
            {
                fields.map((field, key) => {
                    if (field.title === "POS") return (
                        <ConfirmationQuestion key={key} respData={respData} fields={field} fieldValue={fieldValue} />
                    )
                    return false;
                })
            }
            <ConfirmationDisclaimer disclaimerFields={disclaimerFields} setDisclaimerFields={setDisclaimerFields} errorMessage={eMsg} />

            <Row className="mt-3">
                <Col>
                    <center>
                        <Button onClick={() => { nextSlideTransitions(false); window.scrollTo(0, 0); setIsPdfDownloaded(false)}}>{t('button.previous')}</Button><> </>
                        <Button onClick={handleDownload}>{t('button.confirmDownload')}</Button><> </>
                        <Button id={"PayGenerateCIToolTip"} onClick={validationCreate} disabled={!isPdfDownloaded}>
                            {channel === Constant.LOGIN_CHANNEL.PARTNER ? t('generateCI') : t('pay')}
                        </Button>
                        <Tooltip 
                            isOpen={istooltipOpen} 
                            placement="top"
                            target={"PayGenerateCIToolTip"}
                            toggle={isPdfDownloaded ? null : ()=>setIstooltipOpen(!istooltipOpen)}> 
                            {t('pay.generate.ci.tooltip')}
                        </Tooltip> 
                    </center>
                </Col>
            </Row>
        </div>
    )
    else return "";
};

export default Confirmation;