import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Get } from '../../utils/AxFetch';
import * as urlConstant from '../../constants/urlConstant';
import * as Constant from '../../constants/Constant';
import { useTransition, animated } from 'react-spring';
import { FieldLabels, FieldValue, PremiumResp, PremiumValue } from './State';
import ProductInfo from './ProductInfo';
import Proposer from './Proposer/Proposer';
import PremiumPlan from './PremiumPlan/PremiumPlan';
import Confirmation from './Confirmation/Confirmation';

const CertificateInsurance = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [count, setCount] = useState(0);
    const [nextSlide, setNextSlide] = useState(true);
    const [initLoadAddField, setInitLoadAddField] = useState(false);
    const [fieldList, setFieldList] = useState(FieldLabels);
    const [fieldValue, setFieldValue] = useState(FieldValue);
    const [premiumResp, setPremiumResp] = useState(PremiumResp);
    const [premiumValue, setPremiumValue] = useState(PremiumValue);
    const [respData, setRespData] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const params = new URLSearchParams(props.location.search); 
    const prodCode = params.get('code');
    const channel = Constant.LOGIN_CHANNEL.PARTNER;
    const [adultInsuredItems, setAdultInsuredItems] = useState([]);
    const [childrenInsuredItems, setChildrenInsuredItems] = useState([]);

    useEffect(() => {
        const initFieldLists = (respData) => {
            let addFieldLabel = [...fieldList];
            let currentFieldValue = {...fieldValue};
            if (respData.infoFields.length) {
                let infoFields = {};

                for (var infoField of respData.infoFields) {
                    addFieldLabel[1].field.push({key: infoField.key});
                    if (infoField.type === "list") {
                        infoFields[infoField.key] = {value : [""]};
                    } else {
                        infoFields[infoField.key] = {value : ""};
                    }
                }
                currentFieldValue["infoItems"]= infoFields;
            }
            if (respData.riskFields.length) {
                let riskFields = {};
                for (var riskField of respData.riskFields) {
                    addFieldLabel[1].field.push({key: riskField.key});
                    if (riskField.key === "isRoundTrip") {
                        riskFields[riskField.key] = {value : "YES"};
                    } else {
                        riskFields[riskField.key] = {value : ""};
                    }
                }
                currentFieldValue["riskItems"]= riskFields;
            }
            if(respData.proposerFields.length) {
                let proposerFields = {};
                for(var proposerField of respData.proposerFields) {
                    addFieldLabel[1].field.push({key: proposerField.key});
                    proposerFields[proposerField.key] = {value : ""};
                }
                currentFieldValue["dynamicProposerFields"]= proposerFields;
            }
            if(respData.questionFields.length) {
                let questionFields =  {};
                for(var questionField of respData.questionFields) {
                    addFieldLabel[1].field.push({key: questionField.key});
                    questionFields[questionField.key] = {value : ""};
                }
                currentFieldValue["questionItems"] = questionFields;
            }
            setFieldList(addFieldLabel)
            setFieldValue(currentFieldValue);
            setInitLoadAddField(true);
        }

        const retrieveForm = async() => {
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_B2B_PRODUCT_INFO + prodCode, null, dispatch);
            if (!resp.isError && resp.respData.data) {
                setRespData(resp.respData.data);
            }
        }

        if (prodCode && !respData) dispatch(retrieveForm);
        if (respData && !initLoadAddField) initFieldLists(respData);
        if (!nextSlide) setNextSlide(true);
    }, [dispatch, prodCode, respData, nextSlide, fieldList, fieldValue, initLoadAddField])

    const getRespPremiumPlan = (respData, params, newFlag) => {
        let resp = {...premiumResp};
        let value = {...premiumValue};

        resp.basicBenefits = respData.basicBenefits;
        resp.additionalBenefits = respData.additionalBenefits;
        resp.plans = respData.plans;

        value.getPremiumParams = params;

        if (newFlag) {
            value.planSelected.code = "";
            value.planSelected.name = "";
            value.planSelected.coverage.value = "";
            value.planSelected.coverage.desc = "";
            value.planSelected.premium.basicPremium = "";
            value.planSelected.premium.additionalBenefitPremium = "";
            value.planSelected.premium.duration = "";
            value.planSelected.premium.startDate = "";
            value.planSelected.premium.endDate = "";
            value.planSelected.premium.text = "";
            value.planSelected.premium.grossPremium = "";
        }

        let additionalBenefits = respData.additionalBenefits ? respData.additionalBenefits.map((additionalBenefit) => {
            let checked = false;

            if (value.additionalBenefitsChecked.length && !newFlag) {
                for (const additionalBenefitsChecked of value.additionalBenefitsChecked) {
                    if (additionalBenefitsChecked.code === additionalBenefit.code
                        && additionalBenefitsChecked.checked) {
                        checked = true
                        break;
                    }
                }
            }
            return {
                code: additionalBenefit.code,
                desc: additionalBenefit.desc,
                checked: checked
            }
        }) : [];
        value.additionalBenefitsChecked = additionalBenefits;

        setPremiumResp(resp);
        setPremiumValue(value);
    }

    const nextSlideTransitions = (boolean, defaultCount) => {
        if (defaultCount !== undefined) {
            if (boolean) {setCount(defaultCount);setNextSlide(true);}
            else {setCount(defaultCount);setNextSlide(false);}
        } else {
            if (boolean) {setCount((count+1));setNextSlide(true);}
            else {setCount((count-1));setNextSlide(false);}
        }
    }

    const transitions = useTransition(count, (fieldList) => fieldList, {    
        from: {opacity: 0, transform: `translate3d(${nextSlide ? 100 : -100}%,0,0) scale(0.5)`},
        enter: {opacity: 1, transform: "translate3d(0%,0,0) scale(1)"},
        leave: {display: 'none'}
    });

    if (respData) return (
        <div>                
            <Container>
            {errorMessage ? <Row><div className="error largeError">{errorMessage}</div></Row> : null}
            {
                respData.policyType ?
                    respData.policyType == "MASTERPOLICY" ?
                        <>
                            <Row><Col><h2>{t('certificateInsurance')}</h2></Col></Row>
                            <Row><Col><h4><i>{t2('certificateInsurance')}</i></h4></Col></Row>
                        </>
                    :
                        <>
                            <Row><Col><h2>{t('individualCertificateInsurance')}</h2></Col></Row>
                            <Row><Col><h4><i>{t2('individualCertificateInsurance')}</i></h4></Col></Row>
                        </>   
                :
                    <></>
            }
            
            {transitions.map(({ item, props, key }) => {
                let currentFields = fieldList[item]
                if (currentFields.title === "PRODUCT_INFO") {
                    return (
                        <animated.form key={key} style={props}>
                            <ProductInfo 
                                channel={channel}
                                respData={respData} 
                                nextSlideTransitions={nextSlideTransitions}
                            />
                        </animated.form>
                    )
                } else if (currentFields.title === "POS") {
                    return (
                        <animated.form key={key} style={props}>
                            <Proposer 
                                channel={channel}
                                respData={respData} 
                                fields={currentFields}
                                fieldValue={fieldValue}
                                setFieldValue={(val)=>{setFieldValue(val)}}
                                getRespPremiumPlan={getRespPremiumPlan}
                                nextSlideTransitions={nextSlideTransitions}
                                setRespData={setRespData}
                                setAdultInsuredItems={setAdultInsuredItems}
                                setChildrenInsuredItems={setChildrenInsuredItems}
                            />
                        </animated.form>
                    )
                } else if (currentFields.title === "PLAN") {
                    return (
                        <animated.form key={key} style={props}>
                            <PremiumPlan 
                                respData={respData}
                                channel={channel}
                                premiumResp={premiumResp} 
                                setPremiumResp={(val)=>setPremiumResp(val)}
                                premiumValue={premiumValue} 
                                setPremiumValue={(val)=>setPremiumValue(val)}
                                getRespPremiumPlan={getRespPremiumPlan}
                                errorMessage={errorMessage}
                                setErrorMessage={(val)=>{setErrorMessage(val)}}
                                nextSlideTransitions={nextSlideTransitions}
                            />
                        </animated.form>
                    )
                } else if (currentFields.title === "CONFIRMATION") {
                    return (
                        <animated.form key={key} style={props}>
                            <Confirmation 
                                channel={channel}
                                respData={respData} 
                                premiumResp={premiumResp}
                                premiumValue={premiumValue} 
                                fields={fieldList} 
                                fieldValue={fieldValue}
                                nextSlideTransitions={nextSlideTransitions}
                                adultInsuredItems={adultInsuredItems}
                                childrenInsuredItems={childrenInsuredItems}
                            />
                        </animated.form>
                    )
                }
                else return "";
            })}
            </Container>
        </div>
    );
    else return "";
}

export default CertificateInsurance;