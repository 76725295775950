import React, { useState, useEffect, useCallback } from 'react';
import { Button, Label, Input } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import $ from 'jquery';
import TransactionListing from './TransactionListing';
import { useTranslation } from 'react-i18next';
import * as urlConstant from './../../constants/urlConstant';
import { Get } from '../../utils/AxFetch';
import { useDispatch } from 'react-redux';
import * as Validate from './../../utils/CommonValidate';
import DatePicker from './../../components/DatePicker';
import SelectUtil from '../../utils/Select';
import Select from "react-select";

const TransactionEnquiry = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();

    const [companyList,setCompanyList] = useState([]);
    const [productList,setProductList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchCriteria,setSearchCriteria] = useState();
    const [message,setMessage] = useState("");
    const [ciStatusList,setCiStatusList] = useState([]);
    const [policyType, setPolicyType] = useState({policyTypeList:[]});
    const [memberAgentListData, setMemberAgentListData] = useState({memberAgentCodeList:[]});

    let selectCompany = '';

    const validateDt = useCallback(() => {
        let message = '';
        if (!($('input[name="effFrom"]').val() && $('input[name="effTo"]').val())) {
            message += i18n.t('error.message.select.blank', { 0: i18n.t('certificateinsurance.search.txndate') }) +'\n'
        }
        if ( !(Validate.validateDate( $('input[name="effFrom"]').val(), $('input[name="effTo"]').val())) ) {
            message += i18n.t('error.message.greaterthan.enddate') +'\n'
        }
        setMessage(message);
        if (message) {
            return false;
        }
        return true;
    }, [i18n]);

    const getProductList = async dispatch => {
        let prodList = []; 
        let compProductListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_REPORT_COMPANY_PRODUCT_LIST + selectCompany, null, dispatch);
            
        if (!compProductListResp.isError && compProductListResp.respData.data) {
            prodList = compProductListResp.respData.data.map( product => {
                return {value: product.code, label: product.desc} //to accomodate react-select
            }); 
        }
        setProductList(prodList);
    }

    const getPolicyTypeList = async dispatch => {
        let polTypeList = [];
        let polTypeListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_COMMON_POLICY_TYPE_LIST,null,dispatch);
    
        if(!polTypeListResp.isError && polTypeListResp.respData.data){
            polTypeList = polTypeListResp.respData.data.map(policyType => {
                return {shortCode: policyType.code, value:policyType.desc}
            });
        }
        setPolicyType({policyTypeList: polTypeList});
    }

    const getMemberAgentCodeList = async (dispatch, companyName) => {
        let memAgentList = [];
        let memAgentListResp = await Get(urlConstant.URL_BASED + urlConstant.URL_MEMBER_AGENT_CODE_LIST,
            {params:{companyName:companyName}}, dispatch);

        if(!memAgentListResp.isError && memAgentListResp.respData.data){
            memAgentList = Object.entries(memAgentListResp.respData.data).map(([key,value]) => {
                return { shortCode: key, value: value };
            });
        }
        setMemberAgentListData({ memberAgentCodeList: memAgentList });
    }

    const handleChangeCompany = event => {
        setSelectedProducts([]);
        selectCompany = event.target.value;
        const companyName = companyList[event.target.selectedIndex-1].value;   
        getMemberAgentCodeList(dispatch, companyName);
        if (selectCompany) dispatch(getProductList);
    }

    const handleMultiChangeProduct = (selectedProducts) => {
        setSelectedProducts(selectedProducts);
    }

    const handleSearchCriteria = useCallback(() => {
        let searchEffectiveFromDt = '';
        let searchEffectiveToDt = '';
        let searchCompanyId = '';
        let searchCertInsrNo = '';
        let prodCodeList =[];
        let searchProposerName = '';
        let searchCIStatus =[];
        let searchPolicyType = '';
        let searchMemberAgentCode ='';
        if (validateDt()) {
            if ($('select[name="companyId"]').val() !== undefined) {
                searchCompanyId = $('select[name="companyId"]').val();
            }
            if ($('input[name="effFrom"]').val() !== undefined) {
                searchEffectiveFromDt = $('input[name="effFrom"]').val();
            }
            if ($('input[name="effTo"]').val() !== undefined) {
                searchEffectiveToDt = $('input[name="effTo"]').val();
            }
            $("input[name='prodCode']").each(function() {
                if (this.value) prodCodeList.push(this.value);
            });
            if ($('input[name="certInsrNo"]').val() !== undefined) {
                searchCertInsrNo = $('input[name="certInsrNo"]').val();
            }
            if ($('input[name="proposerName"]').val() !== undefined) {
                searchProposerName = $('input[name="proposerName"]').val();
            }
            if ($('select[name="ciStatus"]').val() !== undefined && 
            $('select[name="ciStatus"]').val() !== '') {
                searchCIStatus.push($('select[name="ciStatus"]').val());
            }
            if ($('select[name="policyType"]').val() !== undefined) {
                searchPolicyType = $('select[name="policyType"]').val();
            }
            if ($('select[name="memberAgentCode"]').val() !== undefined) {
                searchMemberAgentCode = $('select[name="memberAgentCode"]').val();
            }
            setSearchCriteria({
                searchCompanyId: searchCompanyId,
                searchProdCodes: prodCodeList,
                searchEffectiveFromDt: searchEffectiveFromDt,
                searchEffectiveToDt: searchEffectiveToDt,
                searchCertInsrNo: searchCertInsrNo,
                searchProposerName: searchProposerName,
                searchCIStatus: searchCIStatus,
                searchPolicyType: searchPolicyType,
                searchMemberAgentCode: searchMemberAgentCode  
            })
        }
    }, [validateDt]);

    const dropdownListComp = useCallback(async dispatch => {
        let activeCompany = [];
        let activeCompanyResp = await Get(urlConstant.URL_BASED + urlConstant.URL_ACCOUNT_ACTIVECOMPANY, null, dispatch);
        
        if(!activeCompanyResp.isError && activeCompanyResp.respData.data) {
            activeCompany = activeCompanyResp.respData.data.map(company => {
                return {shortCode: company.id, value: company.companyName}
            });
            setCompanyList(activeCompany);
        }
    }, []);

    const dropdownCIStatus = useCallback(async dispatch => {
        let ciStatus = [];
        let aciStatusResp = await Get(urlConstant.URL_BASED + urlConstant.URL_TRNX_CI_STATUS, null, dispatch);
        
        if(!aciStatusResp.isError && aciStatusResp.respData.data) {
            ciStatus = aciStatusResp.respData.data.map(statusData => {
                return {shortCode: statusData.shortCode, value: statusData.value}
            });
            setCiStatusList(ciStatus);
        }
    }, []);


    useEffect(() => {
        dropdownListComp(dispatch);
        dropdownCIStatus(dispatch);
        getPolicyTypeList(dispatch);
        handleSearchCriteria();
    }, [dispatch, dropdownListComp, dropdownCIStatus, handleSearchCriteria]);

    return (
        <div>
            <Container>
                { message? <div className="error"><p>{message}</p></div>: null }
                <Row className="mt-3"><Col><h2>{t('transaction.enquiry')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('transaction.search')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                <div></div>
                
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('certificateinsurance.search.txndate')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col sm={{ size: 2 }} md="2"><DatePicker className="form-control datepicker-icon"  selected={startDate} onChange={date => setStartDate(date)} name="effFrom"/></Col>
                    <Col sm={{ size: 1 }} md="1" className="text-center"><Label>{t('effectivedateto')}</Label></Col>
                    <Col sm={{ size: 2 }} md="2" ><DatePicker className="form-control datepicker-icon" selected={endDate} onChange={date => setEndDate(date)} name="effTo"/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('company')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name='companyId' optionList={companyList} onChange={handleChangeCompany}/></Col>    
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('productname')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Select 
                        id='prodCode' 
                        name='prodCode'
                        placeholder={t('placeholder.select')} 
                        options={productList}
                        value={selectedProducts} 
                        onChange={handleMultiChangeProduct} 
                        isMulti
                    /></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('transaction.search.cinumber')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input type="text" name="certInsrNo" maxLength={60}/></Col>    
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('transaction.listing.proposername')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><Input type="text" name="proposerName" maxLength={50}/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('transaction.search.cistatus')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name='ciStatus' optionList={ciStatusList}/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('transaction.search.policytype')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name='policyType' optionList={policyType.policyTypeList}/></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="10" sm="3" md="3"><Label>{t('transaction.search.memberAgentCode')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="5" md="5"><SelectUtil name='memberAgentCode' optionList={memberAgentListData.memberAgentCodeList} /></Col>
                </Row>

                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12">
                        <center><Button name="btnSearch" onClick={()=>{handleSearchCriteria()} } >{t('button.search')}</Button></center>    
                    </Col>
                </Row>
            </Container>
            <TransactionListing searchCriteria={searchCriteria} setMessage={setMessage} />
        </div>
    )
}

export default TransactionEnquiry;