// Base Constant
export const rowPerPage = 10;
export const defaultLanguageCode = "id"; //option: en, id
export const defaultCurrency = "Rp.";
export const generalRupiahCurrecy = "IDR ";
export const statusList = [{ shortCode:true, value:"Active"},{shortCode:false, value:"Inactive"}];
export const milliseconds = 1000;
export const idleWarning = 14 * 60; //minutes
export const idleLogOut = 1 * 60; //minutes
export const secretPassphrase = "axPass*!12n3$567";
export const copyright = "Copyright © 2023 GEGI Indonesia." ;
export const copyrightBtm = "PT Great Eastern General Insurance Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)";
// export const acceptFileType = ".jpg, .jpeg, .png";
export const sourdeId = "ax_react";
export const tokenPrefix = "ax";
export const fileLength = 30;
export const megabyte = (1024 * 1024);
export const kilobyte = 1024;
export const fileSize = 5; //5mb
export const imageSize = 100; //100kb
export const defaultLocale = "id";
export const defaultCountryCode = "id";
export const dateFormat = "yyyy-MM-DD HH:mm:ss.SSS";
export const HTTP_200 = "200";
export const documentRequiredTrue = "Yes";
export const documentRequiredFalse = "No";
export const USERTYPE_STAFF = "STF";

// File Gateway Only
export const FG_CH_fileUpload = "AX_CLM";
export const FG_CH_fileDownload = "AX_CLM_APPR";
export const FG_CH_fileUpload_trnx = "AX_TRNX_UPL";
export const FG_SCAN_VIRUS_FAILED = "FG5011";
export const FG_COMMON_ERROR = "FG_ERROR";
export const FG_DOWNLOAD_ERROR = "FG_DOWNLOAD_ERROR";
export const FG_KeyId = "axfilegw";
export const FG_ClientOrgCode = "ax";
export const FG_SecretKey = "axfilegw";

// Redirect Path
export const PATH_LOGIN = "/login";
export const PATH_ACCOUNT_DETAILS = "/accountDetail";
export const PATH_COMPANY_DETAILS = "/companyDetail";
export const PATH_ROLE_ACCESSCONTROL = "/accessControl";
export const PATH_ROLE_ACCESSCONTROLDET = "/accessControlDetail";
export const PATH_CLAIM_APPROVAL = "/claimAppr";
export const PATH_CLAIM_APPROVAL_DETAILS = "/claimApprDetail";
export const PATH_CLAIM_REGIRTRATION = "/claimRegistration";
export const PATH_INDIVIDUAL_CLAIM_REGIRTRATION ="/individualClaimRegistration";
export const PATH_CLAIM_SUBMISSION = "/claimSubmissionForm";
export const PATH_TRANSACTION_DETAILS = "/transactionDetail";
export const PATH_CERTIFICATE_INSURANCE_B2B = "/partnerPOS";
export const PATH_CERTIFICATE_INSURANCE_B2C = "/consumerPOS";
export const PATH_ACKNOWLEDGEMENT = "/acknowledgement";
export const PATH_PAYMENT_SUBMISSION = "/paymentSubmission";
export const PATH_PAYMENT_OCBC = "/paymentDetails";

//for get txn count by action type
export const TRNX_COUNT_GET_PREMIUM = "GET_PREMIUM";
export const TRNX_COUNT_CREATE_POLICY = "CREATE_POLICY";

export const DEFAULT_ERR = "default";
export const dashboardList = [{ shortCode:'month', value:"Bulanan"},{shortCode:'year', value:"Sejauh Tahun Ini"}];
export const startOfMonth = "01/01/";
export const month = "month";
export const year = "year";
export const dateFormat_A = "yyyy/MM/dd";
export const dateFormat_B = "dd/MM/yyyy";
export const dateFormat_C = "dd-MM-yyyy hh:mm:ss";
export const dateFormat_year = "yyyy";
export const dateFormat_month = "MMM-yyyy";

//for claim approval 
export const REVIEWDOC = "REVIEWDOC";
export const APPROVED = "APPROVED";
export const DECLINED = "DECLINED";
export const NOTIFYPAYMENT = "NOTIFYPAYMENT";
export const PEND_APPVL = "PEND_APPVL";
export const PEND_REUPL = "PEND_REUPL";
export const PAID = "PAID";
export const DRAFT = "DRAFT";

export const downloadType = {GET: "GET", POST: "POST"}
export const FILE_DOWNLOAD_ERROR = "FILE_DOWNLOAD_ERROR";
export const cibatchuploadfile = "ci-batch-file.xlsx";
export const pdfdownloadfile = "pdf-file.pdf";
export const certissuedreportfile = "certicate-insurance-report.csv";
export const claimregistrreportfile = "claim-registration-report.csv";

export const productVersion = "Product Version";
export const filePathType = "File";
export const imagePathType = "Image";
export const excelPathType = "Excel";
export const imageExtension = [".jpeg", ".png", ".jpg", ".gif"];
export const excelExtension = [".xls",".xlsx"];

export const noOfInsuredList = [
    { shortCode:1, value:1 },
    { shortCode:2, value:2 },
    { shortCode:3, value:3 },
    { shortCode:4, value:4 },
    { shortCode:5, value:5 },
    { shortCode:6, value:6 },
    { shortCode:7, value:7 },
    { shortCode:8, value:8 },
    { shortCode:9, value:9 },
    { shortCode:10, value:10 }
];

export const LOGIN_CHANNEL = { PARTNER: "B2B", CONSUMER: "B2C" };

export const LOGO_DISPLAY_STATUS = {
    default: "DEFAULT",
    hide: "HIDE",
    unhide: "UNHIDE"
}

export const PAYMENT_STATUS = {
    PROCESSING: "Processing", 
    FAILED: "Failed", 
	SUCCESS: "Success"
}

export const cryptoConfig = {
    cipherAlgorithm: 'aes-256-gcm',
    masterKey: 'YXhQYXNzKiExMm4zJDU2Nw==',
    ivLength: 12,
    tagLength: 16,
    digest: 'sha512'
}