import React, { useState }from 'react';
import { Button, Col, Collapse, Label, CardBody, Card} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import * as Constant from '../../../../constants/Constant';
import ConfirmationProposerDynamicInfo from './ConfirmationProposerDynamicInfo';

const ConfirmationProposerInfo = ({ respData, field, fieldValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(true);
    const [item] = useState("proposerItems");
    const [value] = useState("value");
    const [desc] = useState("desc");

    const ProposerToggle = () => setPanelOpen(!panelOpen);
  
    return (
        <div>       
            <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
                    onClick={e => ProposerToggle(e)}>{t('proposerInformation')}<br></br><i>{t2('proposerInformation')}</i>
            </Button>
            <Collapse isOpen={panelOpen}>
            <Card><CardBody><div className="container-flex">
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.proposer.name")}<br></br><i>{t2('trnx.proposer.name')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{fieldValue[item]["name"][value]? fieldValue[item]["name"][value]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.proposer.identityNo")}<br></br><i>{t2('trnx.proposer.identityNo')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{fieldValue[item]["identityNo"][value]? fieldValue[item]["identityNo"][value]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.proposer.dob")}<br></br><i>{t2('trnx.proposer.dob')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{fieldValue[item]["dob"][value]? format(fieldValue[item]["dob"][value], Constant.dateFormat_B): "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.proposer.gender")}<br></br><i>{t2('trnx.proposer.gender')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{fieldValue[item]["gender"][desc]? fieldValue[item]["gender"][desc]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.proposer.email")}<br></br><i>{t2('trnx.proposer.email')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{fieldValue[item]["email"][value]? fieldValue[item]["email"][value]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.proposer.mobileNo")}<br></br><i>{t2('trnx.proposer.mobileNo')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{fieldValue[item]["mobileNo"][value]? fieldValue[item]["mobileNo"][value]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.proposer.address")}<br></br><i>{t2('trnx.proposer.address')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label className='col-12'>{fieldValue[item]["fullAddress"][value]? fieldValue[item]["fullAddress"][value]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.proposer.province")}<br></br><i>{t2('trnx.proposer.province')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{fieldValue[item]["province"][desc]? fieldValue[item]["province"][desc]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="4" md="4"><Label>{t("trnx.proposer.city")}<br></br><i>{t2('trnx.proposer.city')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="7">
                        <Label>{fieldValue[item]["city"][desc]? fieldValue[item]["city"][desc]: "-"}</Label>
                    </Col>
                </Col>
                <Col xs="12" sm="6" md="6" className="mt-3 container-flex">
                    <Col xs="10" sm="5" md="5"><Label>{t("trnx.proposer.postCode")}<br></br><i>{t2('trnx.proposer.postCode')}</i></Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="6" md="6">
                        <Label>{fieldValue[item]["postCode"][value]? fieldValue[item]["postCode"][value]: "-"}</Label>
                    </Col>
                </Col>            
            </div>
            <ConfirmationProposerDynamicInfo respData={respData} field={field} fieldValue={fieldValue}/>
            </CardBody></Card>
            </Collapse>                
        </div>
    )
};

export default ConfirmationProposerInfo;