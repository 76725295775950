import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from '../../../utils/Select';
import * as Constant from './../../../constants/Constant';
import DatePicker from './../../../components/DatePicker';
import * as Validate from '../../../utils/CommonValidate';
import InfoField from './InfoFields';
import RiskLength from './risk_length.json';
import { Get } from './../../../utils/AxFetch';
import * as urlConstant from './../../../constants/urlConstant';
import AutoComplete from './../../../utils/AutoComplete';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const RiskItems = ({ respData, fields, fieldValue, setNoOfInsured, onChangeInputSelect, onChangeCurrency, onChangeDate, validateEffectiveDate, dropdownList, onChangeList, handleAddList, handleRemoveList, setFieldValue, setRespData, isEffectiveEndDateReadOnly, onChangeDurationSelect, clearAdultChildrenInsuredItems,handleUncheckCheckbox}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const t2 = (label) => {
        return t('id_riskitems:'+label); 
    }
    const t3 = (label) => {
        return t('en_riskitems:'+label); 
    }
    const [panelOpen, setPanelOpen] = useState(false);

    const toggle = () => {setPanelOpen(!panelOpen)};

    const getDesCountry = (id) => {
            let categoryShortCode = id || "ALL";
            Get(
                urlConstant.URL_BASED + urlConstant.URL_COMMON_DESTINATION_CATEGORY + `${categoryShortCode}`,
                {},
                dispatch
            ).then(res => {
                    if (!res.isError) {
                        const countries = res.respData.data.commonLookupList; 
                        let newRespData = structuredClone(respData); 
                        newRespData.riskFields.forEach((riskField) => {
                            if("travelDestCountry" === riskField.key) {
                                riskField.options = countries; 
                                setRespData(newRespData); 
                            }
                        });    
                    }
                });
        };

        const getDesCategory = (id) => {
            let countryShortCode = id || "ALL";
            Get(
                urlConstant.URL_BASED + urlConstant.URL_COMMON_DESTINATION_COUNTRY + `${countryShortCode}`,
                {},
                dispatch
            ).then(res => {
                    if (!res.isError) {
                        const category = res.respData.data.commonLookupList; 
                        let newRespData = structuredClone(respData); 
                        newRespData.riskFields.forEach((riskField) => {
                            if("travelDestCategory" === riskField.key) {
                                riskField.options = category; 
                                setRespData(newRespData); 
                            }
                        });    
                    }
                });
        };
    if (respData.riskFields) return (
        <div>
        <Button className={"accordion-toggle mt-3 " + (panelOpen ? "collapse-icon" : "expand-icon")} 
            onClick={e => toggle(e)}>{t("generalInfo")}<br></br><i>{t('en_translations:generalInfo')}</i>
        </Button>
        <Collapse isOpen={panelOpen}>
            <Card>
                <CardBody>
                    <div className="container-flex" xs="12" sm="12" md="12">
                    {fields.field.map((f,i) => {
                        return respData.riskFields.map((respField,i) => {
                            if (respField.key === f.key) {
                                if(f.key === "houseType") {
                                    if(fieldValue["riskItems"]["propertyType"] && fieldValue["riskItems"]["propertyType"].value === "HOUSE") {
                                        return(
                                            <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                                <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                    <Label className={respField.isRequired ? "required" : ""}>
                                                        {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                        <br></br>
                                                        <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                    </Label>
                                                </Col>
                                                <Col xs="2" sm="1" md="1">:</Col>
                                                <Col xs="12" sm="6" md="6">
                                                <div>
                                                    {respField.type === "dropdown" ? 
                                                        <AutoComplete
                                                            name={f.key}
                                                            optionList={dropdownList(respField.options)}
                                                            defaultVal={fieldValue["riskItems"][f.key].desc}
                                                            fieldValue={fieldValue}
                                                            item="riskItems"
                                                            respData={respData}
                                                            setFieldValue={e => setFieldValue(e)}
                                                            setRespData={setRespData}
                                                        />
                                                    : ""
                                                    }
                                                    <div className="error"><span id={"errMsg_"+f.key}/></div>
                                                </div>
                                                </Col>
                                            </Col>
                                        )
                                    } else return false
                                }
                                if(f.key === "noOfFloors") {
                                    if(fieldValue["riskItems"]["propertyType"] && (fieldValue["riskItems"]["propertyType"].value === "APARTMENT" || fieldValue["riskItems"]["propertyType"].value === "OFFICE")) {
                                        return(
                                            <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                                <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                    <Label className={respField.isRequired ? "required" : ""}>
                                                        {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                        <br></br>
                                                        <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                    </Label>
                                                </Col>
                                                <Col xs="2" sm="1" md="1">:</Col>
                                                <Col xs="12" sm="6" md="6">
                                                <div>
                                                    {respField.type === "numeric" && f.key !== "noOfInsured" && !respField.isCurrency ? <Input name={f.key} maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100} value={fieldValue["riskItems"][f.key].value} onChange={(e) => onChangeInputSelect(e, "riskItems", false)} onKeyPress={(event) => Validate.numeric(event)}/>
                                                    : ""
                                                    }
                                                    <div className="error"><span id={"errMsg_"+f.key}/></div>
                                                </div>
                                                </Col>
                                            </Col>
                                        )
                                    } else return false
                                }
                                if(
                                    f.key === "riskFullAddress" ||
                                    f.key === "riskAddress1" ||
                                    f.key === "riskAddress2" ||
                                    f.key === "riskAddress3"
                                )  {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                                <Input
                                                    rows={3}
                                                    name={f.key} 
                                                    type='textarea'
                                                    maxLength={f.key === "riskFullAddress" ? 150 : RiskLength[f.key]} 
                                                    value={fieldValue["riskItems"][f.key].value} 
                                                    onChange={e => onChangeInputSelect(e, "riskItems", false)} 
                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if(f.key === "riskCity") {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                                <AutoComplete
                                                    name={f.key}
                                                    optionList={dropdownList(respField.options)}
                                                    defaultVal={fieldValue["riskItems"][f.key].desc}
                                                    fieldValue={fieldValue}
                                                    item="riskItems"
                                                    respData={respData}
                                                    setFieldValue={e => setFieldValue(e)}
                                                    setRespData={setRespData}
                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if(f.key === "riskProvince") {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                                <AutoComplete
                                                    name={f.key}
                                                    defaultVal={fieldValue["riskItems"][f.key].desc} 
                                                    fieldValue={fieldValue}
                                                    setFieldValue={e => setFieldValue(e)}
                                                    item="riskItems"
                                                    setRespData={setRespData}
                                                    respData={respData}
                                                    optionList={dropdownList(respField.options)}
                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if (f.key === "travelDestCategory") {
                                    return (
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key}
                                                    <br />
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key}</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                                <div>
                                                <AutoComplete
                                                        name={f.key}
                                                        defaultVal={fieldValue["riskItems"][f.key].desc} 
                                                        fieldValue={fieldValue}
                                                        setFieldValue={e => setFieldValue(e)}
                                                        item="riskItems"
                                                        setRespData={setRespData}
                                                        respData={respData}
                                                        optionList={dropdownList(respField.options)}
                                                        destCategoryOnChange={() => {getDesCountry(fieldValue["riskItems"][f.key].value)}}

                                                    />
                                                    <div className="error"><span id={"errMsg_" + f.key} /></div>
                                                </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if (f.key === "travelDestCountry") {
                                    return (
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key}
                                                    <br />
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key}</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                                <AutoComplete
                                                        name={f.key}
                                                        defaultVal={fieldValue["riskItems"][f.key].desc} 
                                                        fieldValue={fieldValue}
                                                        setFieldValue={e => setFieldValue(e)}
                                                        item="riskItems"
                                                        setRespData={setRespData}
                                                        respData={respData}
                                                        optionList={dropdownList(respField.options)}
                                                        destCountryOnChange={() => {getDesCategory(fieldValue["riskItems"][f.key].value)}}

                                                    />
                                                    <div className="error"><span id={"errMsg_" + f.key} /></div>
                                                </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                            
                                if(f.key === "totalSumInsured") {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                                <Input
                                                    name={f.key}
                                                    maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100}
                                                    value={fieldValue["riskItems"][f.key].value !== "" ? 
                                                        parseInt(fieldValue["riskItems"][f.key].value).toLocaleString(Constant.defaultLocale)
                                                    : 
                                                        ""
                                                    }
                                                    onChange={(e) => onChangeCurrency(e, "riskItems")}
                                                    onKeyPress={(event) => Validate.numeric(event)}
                                                    readOnly={true}
                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if(f.key === "tripType") {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                            <AutoComplete
                                                    name={f.key}
                                                    defaultVal={fieldValue["riskItems"][f.key].desc} 
                                                    fieldValue={fieldValue}
                                                    setFieldValue={e => setFieldValue(e)}
                                                    item="riskItems"
                                                    setRespData={setRespData}
                                                    respData={respData}
                                                    optionList={dropdownList(respField.options)}
                                                    tripTypeOnChange={() => {clearAdultChildrenInsuredItems(),handleUncheckCheckbox()}}

                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if(f.key === "durationType") {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                            <AutoComplete
                                                    name={f.key}
                                                    defaultVal={fieldValue["riskItems"][f.key].desc} 
                                                    fieldValue={fieldValue}
                                                    setFieldValue={e => setFieldValue(e)}
                                                    item="riskItems"
                                                    setRespData={setRespData}
                                                    respData={respData}
                                                    optionList={dropdownList(respField.options)}
                                                    durationTypeOnchange={e => {onChangeDurationSelect(e, f.key, fieldValue["riskItems"][f.key].value) }}

                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                if(respField.type === "numeric" && f.key === "noOfInsured") {
                                    return(
                                        <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                            <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                                <Label className={respField.isRequired ? "required" : ""}>
                                                    {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                    <br></br>
                                                    <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                                </Label>
                                            </Col>
                                            <Col xs="2" sm="1" md="1">:</Col>
                                            <Col xs="12" sm="6" md="6">
                                            <div>
                                            <AutoComplete
                                                    name={f.key}
                                                    defaultVal={fieldValue["riskItems"][f.key].desc} 
                                                    fieldValue={fieldValue}
                                                    setFieldValue={e => setFieldValue(e)}
                                                    item="riskItems"
                                                    setRespData={setRespData}
                                                    respData={respData}
                                                    optionList={dropdownList(respField.options)}
                                                    noOfInsuredOnChange={e => {setNoOfInsured(e.target.value)}}
                                                />
                                                <div className="error"><span id={"errMsg_"+f.key}/></div>
                                            </div>
                                            </Col>
                                        </Col>
                                    )
                                }
                                return (
                                    <Col key={i} xs="12" sm="6" md="6" className="mt-3 container-flex">
                                        <Col xs="10" sm="5" md="5" className="paddingRightZero">
                                            <Label className={respField.isRequired ? "required" : ""}>
                                                {respField.axLabel && respField.axLabel.axLabelId ? respField.axLabel.axLabelId : t2(f.key) ? t2(f.key) : f.key }
                                                <br></br>
                                                <i>{respField.axLabel && respField.axLabel.axLabelEn ? respField.axLabel.axLabelEn : t3(f.key) ? t3(f.key) : f.key }</i>
                                            </Label>
                                        </Col>
                                        <Col xs="2" sm="1" md="1">:</Col>
                                        <Col xs="12" sm="6" md="6">
                                        <div>
                                            {respData.riskFields.filter(e => e.key === 'effectiveStartDate').length > 0 && respData.riskFields.filter(e => e.key === 'effectiveEndDate').length > 0 && f.key === "duration" ? <Input name={f.key} maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100} value={fieldValue["riskItems"][f.key].value} readOnly/>
                                            : respField.type === "datepicker" && f.key === "effectiveEndDate" ? <DatePicker readOnly={isEffectiveEndDateReadOnly} className="form-control datepicker-icon" placeholderText={t('placeholder.dateFormat')} selected={fieldValue["riskItems"][f.key].value} onChange={value => {onChangeDate(value, "riskItems", f.key);validateEffectiveDate(value, f.key)}} name={f.key}/>
                                            : respField.type === "numeric" && f.key === "riskAge" && !respField.isCurrency ?<Input name={f.key} maxLength={3} value={fieldValue["riskItems"][f.key].value} onChange={(e) => onChangeInputSelect(e, "riskItems", false)} onKeyPress={(event) => Validate.numeric(event)} readOnly={(fieldValue["proposerItems"]["dob"].value)}/>
                                            : respField.type === "numeric" && f.key !== "noOfInsured" && !respField.isCurrency ? <Input name={f.key} maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100} value={fieldValue["riskItems"][f.key].value} onChange={(e) => onChangeInputSelect(e, "riskItems", false)} onKeyPress={(event) => Validate.numeric(event)}/>
                                            : respField.type === "numeric" && f.key !== "noOfInsured" && respField.isCurrency ? <Input name={f.key} maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100} value={fieldValue["riskItems"][f.key].value !== "" ? parseInt(fieldValue["riskItems"][f.key].value).toLocaleString(Constant.defaultLocale) : ""} onChange={(e) => onChangeCurrency(e, "riskItems")} onKeyPress={(event) => Validate.numeric(event)}/>
                                            : respField.type === "alphanumeric" ? <Input name={f.key} maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100} value={fieldValue["riskItems"][f.key].value} onChange={e => onChangeInputSelect(e, "riskItems", false)} onKeyPress={(event) => Validate.alphanumeric(event)}/>
                                            : respField.type === "freetext" && f.key === "isRoundTrip" ? <div><input type="radio" name={f.key} value="YES" id={f.key +"-yes"} onClick={(e) => onChangeInputSelect(e, "riskItems", false)} defaultChecked/>&nbsp;
                                                    <label>{t('button.yes')}</label>&emsp;
                                                    <input type="radio" name={f.key} value="NO" id={f.key +"-no"} onClick={(e) => onChangeInputSelect(e, "riskItems", false)}/>&nbsp;
                                                    <label>{t('button.no')}</label>
                                                </div>
                                            : respField.type === "freetext" ? <Input name={f.key} maxLength={RiskLength[f.key] ? RiskLength[f.key] : 100} value={fieldValue["riskItems"][f.key].value} onChange={e => onChangeInputSelect(e, "riskItems", false)} />
                                            : respField.type === "datepicker" ? <DatePicker className="form-control datepicker-icon" placeholderText={t('placeholder.dateFormat')} selected={fieldValue["riskItems"][f.key].value} onChange={value => {onChangeDate(value, "riskItems", f.key);validateEffectiveDate(value, f.key)}} name={f.key}/>
                                            : respField.type === "dropdown" ? <AutoComplete name={f.key} defaultVal={fieldValue["riskItems"][f.key].desc} fieldValue={fieldValue} setFieldValue={e => setFieldValue(e)} item="riskItems" setRespData={setRespData} respData={respData} optionList={dropdownList(respField.options)}/>
                                            : ""
                                            }
                                            <div className="error"><span id={"errMsg_"+f.key}/></div>
                                        </div>
                                        </Col>
                                    </Col>
                                )
                            }else return false;
                        })
                    })}
                    </div>
                    <InfoField 
                        respData={respData} 
                        fields={fields} 
                        fieldValue={fieldValue} 
                        dropdownList={dropdownList} 
                        onChangeList={onChangeList} 
                        handleAddList={handleAddList} 
                        handleRemoveList={handleRemoveList}
                    />
                </CardBody>
            </Card>
        </Collapse>
        </div>
    )
    else return "";
};

export default RiskItems;